ptz-tooltip {
  .ptz-tooltip {
    cursor: pointer;
    display: inline-block;
    position: relative;
    box-sizing: border-box;

    &-span {
      display: inline-block;
      position: absolute;
      z-index: 1;
      font-weight: $petz-font-weight-regular;
      font-size: $petz-font-size-2xs;
      line-height: $petz-font-lineheight-lg;
      padding: $petz-spacing-sm $petz-spacing-xs;
      border-radius: $petz-rounded-xs;
      border-width: $petz-border-md;
      border-style: solid;
      box-sizing: border-box;
      min-width: 30px;
      white-space: nowrap;
      pointer-events: none;
      visibility: hidden;

      &-visible {
        visibility: visible;
      }

      &-max-width {
        min-width: 200px;
        white-space: normal;
      }

      &:after {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        margin-left: -12px;
        margin-top: -8px;
        border-style: solid;
        transform: rotate(45deg);
      }

      &-light,
      &-light:after {
        color: $petz-color-neutral-darker-accent;
        border-color: $petz-color-neutral-mid;
        background-color: $petz-color-neutral-lighter-accent;
      }

      &-dark,
      &-dark:after {
        color: $petz-color-neutral-white;
        border-color: $petz-color-neutral-darker-accent;
        background-color: $petz-color-neutral-darker-accent;
      }

      &-bottom-left {
        top: 100%;
        transform: translateY(14px);
        right: -18px;
        text-align: right;

        &:after {
          border-width: 2px 0 0 2px;
          top: -3px;
          right: $petz-spacing-sm;
        }
      }

      &-bottom-right {
        top: 100%;
        transform: translateY(14px);
        left: -16px;
        text-align: left;

        &:after {
          border-width: 2px 0 0 2px;
          top: -3px;
          left: $petz-spacing-md;
        }
      }

      &-bottom-center {
        top: 100%;
        transform: translate(-48%, 14px);
        left: 48%;
        text-align: center;

        &:after {
          border-width: 2px 0 0 2px;
          top: -3px;
          left: 50%;
        }
      }

      &-top-left {
        bottom: 100%;
        transform: translateY(-14px);
        right: -18px;
        text-align: right;

        &:after {
          border-width: 0 2px 2px 0;
          bottom: -11px;
          right: $petz-spacing-sm;
        }
      }

      &-top-right {
        bottom: 100%;
        transform: translateY(-14px);
        left: -16px;
        text-align: left;

        &:after {
          border-width: 0 2px 2px 0;
          bottom: -11px;
          left: $petz-spacing-md;
        }
      }

      &-top-center {
        bottom: 100%;
        transform: translate(-48%, -14px);
        left: 48%;
        text-align: center;

        &:after {
          border-width: 0 2px 2px 0;
          bottom: -11px;
          left: 50%;
        }
      }

      &-middle-left {
        top: 50%;
        right: 100%;
        margin-right: 14px;
        transform: translateY(-50%);
        text-align: right;

        &:after {
          border-width: 2px 2px 0 0;
          top: 50%;
          right: -11px;
        }
      }

      &-middle-right {
        top: 50%;
        left: 100%;
        margin-left: 14px;
        transform: translateY(-50%);
        text-align: left;

        &:after {
          border-width: 0 0 2px 2px;
          top: 50%;
          left: 1px;
        }
      }
    }
  }
}

import { EnumToArray } from '../../../utils/enumToArray';

enum Align {
  BottomCenter = 'bottom-center',
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right',
  MiddleLeft = 'middle-left',
  MiddleRight = 'middle-right',
  TopCenter = 'top-center',
  TopLeft = 'top-left',
  TopRight = 'top-right',
}

enum Kind {
  Dark = 'dark',
  Light = 'light',
}

export const PTZTooltipConfigList = {
  Align: EnumToArray([Align]),
  Kind: EnumToArray([Kind]),
};

export const PTZTooltipConfig = {
  Align,
  Kind,
};

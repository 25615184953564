import { Component, h, Host, Listen, Prop, State } from '@stencil/core';
import { currentId } from '../../utils/id';
import { getSkeletonWidth, valueToLowerCaseForDataTestId } from '../../utils/utils';
import { PTZTooltipConfig } from './types/ptz-tooltip.enums';
import { PTZTooltipTypes } from './types/ptz-tooltip.types';

@Component({
  tag: 'ptz-tooltip',
  styleUrl: 'ptz-tooltip.scss',
  shadow: false,
})
export class PTZTooltip {
  /** Alinhamento do tooltip */
  @Prop() align?: PTZTooltipTypes.Align = PTZTooltipConfig.Align.BottomLeft;

  /** Mode do tooltip */
  @Prop() kind: PTZTooltipTypes.Kind = PTZTooltipConfig.Kind.Light;

  /** Texto do tooltip */
  @Prop() label: string;

  /** Preenche id do tooltip */
  @Prop() tooltipId?: string;

  /** Estado de renderização */
  @Prop() skeleton: boolean = false;

  @State() visible: boolean = false;
  @State() maxWidth: boolean = false;

  private currentTooltipId = currentId(this.tooltipId);

  private isVisible(currentVisible: boolean) {
    this.visible = currentVisible;
  }

  private reversePosition(out: 'top' | 'right' | 'bottom' | 'left') {
    switch (this.align) {
      case PTZTooltipConfig.Align.BottomCenter:
        switch (out) {
          case 'right':
            this.align = PTZTooltipConfig.Align.BottomLeft;
            break;

          case 'bottom':
            this.align = PTZTooltipConfig.Align.TopCenter;
            break;

          case 'left':
            this.align = PTZTooltipConfig.Align.BottomRight;
            break;
        }
        break;

      case PTZTooltipConfig.Align.BottomLeft:
        switch (out) {
          case 'bottom':
          case 'left':
            this.align = PTZTooltipConfig.Align.TopRight;
            break;
        }
        break;

      case PTZTooltipConfig.Align.BottomRight:
        switch (out) {
          case 'bottom':
          case 'right':
            this.align = PTZTooltipConfig.Align.TopLeft;
            break;
        }
        break;

      case PTZTooltipConfig.Align.MiddleLeft:
        switch (out) {
          case 'top':
            this.align = PTZTooltipConfig.Align.BottomLeft;
            break;

          case 'bottom':
            this.align = PTZTooltipConfig.Align.TopLeft;
            break;

          case 'left':
            this.align = PTZTooltipConfig.Align.MiddleRight;
            break;
        }
        break;

      case PTZTooltipConfig.Align.MiddleRight:
        switch (out) {
          case 'top':
            this.align = PTZTooltipConfig.Align.BottomRight;
            break;

          case 'right':
            this.align = PTZTooltipConfig.Align.MiddleLeft;
            break;

          case 'bottom':
            this.align = PTZTooltipConfig.Align.TopRight;
            break;
        }
        break;

      case PTZTooltipConfig.Align.TopCenter:
        switch (out) {
          case 'top':
            this.align = PTZTooltipConfig.Align.BottomCenter;
            break;

          case 'right':
            this.align = PTZTooltipConfig.Align.BottomLeft;
            break;

          case 'left':
            this.align = PTZTooltipConfig.Align.BottomRight;
            break;
        }
        break;

      case PTZTooltipConfig.Align.TopLeft:
        switch (out) {
          case 'top':
          case 'left':
            this.align = PTZTooltipConfig.Align.BottomRight;
            break;
        }
        break;

      case PTZTooltipConfig.Align.TopRight:
        switch (out) {
          case 'top':
          case 'right':
            this.align = PTZTooltipConfig.Align.BottomLeft;
            break;
        }
        break;
    }
  }

  private properPosition(_event: MouseEvent, id: string) {
    const tooltip = document.getElementById(id).getBoundingClientRect();

    this.maxWidth = tooltip.width > 200;

    if (tooltip.y < 0) {
      this.reversePosition('top');
    }

    if (window.innerWidth < tooltip.x + tooltip.width) {
      this.reversePosition('right');
    }

    if (window.innerHeight < tooltip.y + tooltip.height) {
      this.reversePosition('bottom');
    }

    if (tooltip.x < 0) {
      this.reversePosition('left');
    }
  }

  @Listen('keydown', { target: 'document' })
  handleKeyDown(event: KeyboardEvent) {
    if (event.code === 'Escape') {
      this.isVisible(false);
    }
  }

  render() {
    const intervals = [{ max: 24, w: 150 }];
    const skeletonHeight = this.label.length >= 29 ? 34 : 20;
    return (
      <Host>
        <div
          data-testid={`${valueToLowerCaseForDataTestId(this.label)}-hover-tooltip`}
          class={`ptz-tooltip`}
          onMouseEnter={event => this.properPosition(event, this.currentTooltipId)}
          onMouseOver={() => this.isVisible(true)}
          onMouseOut={() => this.isVisible(false)}
        >
          <span
            role="tooltip"
            id={this.currentTooltipId}
            class={`
              ptz-tooltip-span
              ${this.maxWidth ? `ptz-tooltip-span-max-width` : ''}
              ${this.visible ? `ptz-tooltip-span-visible` : ''}
              ${this.kind ? `ptz-tooltip-span-${this.kind}` : ''}
              ${this.align ? `ptz-tooltip-span-${this.align}` : ''}
            `}
          >
            {this.skeleton ? <ptz-skeleton width={getSkeletonWidth(this.label.length, intervals, 100)} height={skeletonHeight}></ptz-skeleton> : this.label}
          </span>
          <slot />
        </div>
      </Host>
    );
  }
}
